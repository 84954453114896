import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const { t } = useTranslation('404');
  return (
  <Layout>
    <Seo title="404: Not found" />
    <h1>404: {t("heading")}</h1>
    <p>{t("paragraph1")}</p>
  </Layout>
  )
}

export default NotFoundPage
